<template>
  <div class="commonProblem">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='commonProblem'">
        <div class="table_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="常见问题" name="first"></el-tab-pane>
          </el-tabs>
          <div class="opera_btnArea">
            <el-button class="mainBtn" v-for="(item, index) in labelData" :key="index" @click="changeEvent(item.id)" plain>{{item.name}}</el-button>
            <el-button class="mainBtn flexs" @click="jumpEvent('add')" plain>+新建文章</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center">
            <el-table-column
              prop="title"
              label="文章名称"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.title" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.title || '/'}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              label="排序"
              align="center">
            </el-table-column>
            <el-table-column
              prop="platformName"
              label="平台"
              align="center">
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="140"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" slot="reference" @click="jumpEvent('edit', scope.row)">修改</el-button>
                <el-button @click="deleteEvent(scope.row)" type="text" v-allow="'sys_order_del'">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
        <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
        <div slot="dialogContent" class="dia_opera_common">
          <img class="errIcon" src="../../assets/img/warn.png" alt="">
          <p>确定要删除该常见问题吗？</p>
          <div class="btn_area">
            <el-button @click="dialogDiyEvent">关闭</el-button>
            <el-button class="mainBtn" @click="doEvent">确定</el-button>
          </div>
        </div>
      </dialogDiy>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import Pagination from '../Pagination'
import publicChildren from '../publicChildren'
import dialogDiy from '../commonModule/dialogDiy'
export default {
  name: 'commonProblem',
  components: {
    Pagination,
    publicChildren,
    dialogDiy
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        categoryId: null,
        current: 1,
        size: 10
      },
      labelData: [],
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      isShow: false,
      rowData: {},
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'commonProblem') {
          this.getColumnEvent();
        }
      }
    }
  },
  methods: {
    getColumnEvent() {
      this.$request.getColumn({id: 2}, res => {
        if(res.code == 0) {
          this.labelData = res.data;
          this.formInline.categoryId = res.data[0].id;
          this.getArticleData();
        }
      })
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.current = val.current;
      this.formInline.size = val.size;
      this.getArticleData();
    },
    getArticleData() {
      this.$request.getArticle(this.formInline, res => {
        if(res.code == 0) {
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    changeEvent(type) {
      this.formInline.categoryId = type;
      this.getArticleData();
    },
    jumpEvent(type, row = {}) {
      let query = type == 'add' ? { status: 'add' } : {
        status: 'edit',
        id: row.id,
        headTitle: '编辑文章'
      }
      this.$router.push({name: 'addArticle', query})
    },
    deleteEvent(row) {
      this.rowData = row;
      this.dialogDiyEvent();
    },
    dialogDiyEvent() {
      this.isShow = !this.isShow;
    },
    // 删除
    doEvent() {
      this.$request.deleteArticleById({id: this.rowData.id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.dialogDiyEvent();
          this.getArticleData();
        }
      })
    }
  }
}
</script>

<style lang='less'>
  .commonProblem {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
        .flexs {
          float: right;
        }
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .dia_opera_common {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
</style>